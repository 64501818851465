import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import TopicList from './components/TopicList';
import TopicDetail from './components/TopicDetail';
import SubtopicDetail from './components/SubtopicDetail';
import Mission from './components/Mission';
import Login from './components/Login';
import Register from './components/Register';
import EmailManagement from './components/EmailManagement';
import Footer from './components/Footer';
import MediaInsights from './components/MediaInsights';
import SearchResults from './components/SearchResults';
import SearchBar from './components/SearchBar'; // Ensure you import SearchBar
import logo from './assets/The Other Side.png';
import './App.css';

const generateUrl = (name) => {
  if (!name) {
    console.error('Name is undefined or null:', name);
    return ''; // Return a default value or handle it as necessary
  }
  return name.toLowerCase().replace(/ /g, '-').replace(/\//g, '-');
};

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleLogin = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const handleSearch = async (query) => {
    console.log('Search query:', query); // Log the query to check if it's correct
    try {
      const response = await fetch(`/api/search?q=${encodeURIComponent(query)}`);
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        const results = await response.json();
        console.log('Search results:', results); // Log the results to check if they're correct
        setSearchResults(results);
      } else {
        const errorText = await response.text();
        console.error('Unexpected response:', errorText);
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <NavLink to="/" className="navbar-brand">
              <img src={logo} alt="The Other Side" className="navbar-logo" />
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink to="/topics" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>Topics</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/mission" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>Mission</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/register" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>Register</NavLink>
                </li>
                {user ? (
                  <>
                    <li className="nav-item">
                      <span className="nav-link">Welcome, {user.username}</span>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link btn btn-link" onClick={handleLogout}>Logout</button>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <NavLink to="/login" className="nav-link login-link">Login</NavLink>
                  </li>
                )}
              </ul>
              <SearchBar onSearch={handleSearch} /> {/* Use the SearchBar component here */}
            </div>
          </div>
        </nav>
        <div className="content container">
          <Routes>
            <Route path="/" element={<TopicList />} />
            <Route path="/topics" element={<TopicList />} />
            <Route path="/topics/:name" element={<TopicDetail />} />
            <Route path="/subtopics/:identifier" element={<SubtopicDetail user={user} />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/email-management" element={<EmailManagement />} />
            <Route path="/mediainsights" element={<MediaInsights />} />
            <Route path="/search" element={<SearchResults results={searchResults} />} /> {/* Add this line */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
