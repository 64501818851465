import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import './SubtopicDetail.css';

const generateUrl = (name) => {
  if (!name) {
    console.error('Name is undefined or null:', name);
    return '';
  }
  return name.toLowerCase().replace(/ /g, '-').replace(/\//g, '-');
};

const normalizeIdentifier = (identifier) => {
  return identifier.replace(/-/g, ' ').replace(/%20/g, ' ');
};

const MAX_COMMENT_LENGTH = 2000;
const MAX_COMMENTS_PER_USER = 3;

const SubtopicDetail = ({ user }) => {
  const { identifier } = useParams();
  const [subtopic, setSubtopic] = useState(null);
  const [parentTopic, setParentTopic] = useState(null);
  const [showOptionOne, setShowOptionOne] = useState(true);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [replyContent, setReplyContent] = useState('');
  const [videoPlayer, setVideoPlayer] = useState({});

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const normalizedIdentifier = normalizeIdentifier(identifier);

    const fetchSubtopic = async () => {
      try {
        const subtopicResponse = await axios.get(`${apiUrl}/api/subtopics/${normalizedIdentifier}`);
        const subtopicData = subtopicResponse.data;

        if (subtopicData) {
          setSubtopic(subtopicData);

          // Fetch all topics to find the parent topic
          const topicsResponse = await axios.get(`${apiUrl}/api/topics`);
          const topics = topicsResponse.data;
          const parent = topics.find(topic => topic.subtopics.some(sub => sub._id === subtopicData._id || normalizeIdentifier(sub.name) === normalizedIdentifier));
          setParentTopic(parent);
          fetchComments(subtopicData._id);
        } else {
          console.error('No subtopic found with the given identifier');
        }
      } catch (error) {
        console.error('Error fetching subtopic:', error);
      }
    };

    fetchSubtopic();
  }, [identifier]);

  const fetchComments = async (subtopicId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/subtopics/${subtopicId}/comments`);
      if (Array.isArray(response.data)) {
        setComments(response.data.filter(comment => comment.content && comment.content.trim() && comment.username));
      } else {
        console.error('Expected array but received:', response.data);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (newComment.length > MAX_COMMENT_LENGTH) {
      alert(`Comment cannot exceed ${MAX_COMMENT_LENGTH} characters.`);
      return;
    }
    if (comments.filter(comment => comment.username === user.username).length >= MAX_COMMENTS_PER_USER) {
      alert(`You can only post up to ${MAX_COMMENTS_PER_USER} comments per subtopic.`);
      return;
    }
    const apiUrl = process.env.REACT_APP_API_URL;
    const normalizedIdentifier = normalizeIdentifier(identifier);

    try {
      console.log('Submitting comment:', newComment, 'Username:', user.username);
      const response = await axios.post(`${apiUrl}/api/subtopics/${normalizedIdentifier}/comments`, { content: newComment, username: user.username });
      if (response.data && response.data.content) {
        setComments([...comments, { ...response.data, createdAt: new Date() }]);
        setNewComment('');
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(`${apiUrl}/api/comments/${commentId}/replies`, { content: replyContent, username: user.username });
      if (response.data && response.data.content) {
        setComments(comments.map(comment =>
          comment._id === commentId ? { ...comment, replies: [...comment.replies, response.data] } : comment
        ));
        setReplyContent('');
        setReplyCommentId(null);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error submitting reply:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      await axios.delete(`${apiUrl}/api/comments/${commentId}`);
      setComments(comments.filter(comment => comment._id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleThumbnailClick = (index, videoLink) => {
    const videoId = new URL(videoLink).searchParams.get('v');
    const autoplayLink = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
    setVideoPlayer({ ...videoPlayer, [index]: autoplayLink });
  };

  if (!subtopic) return <div className="container mt-5">Loading...</div>;

  const isToggleSubtopic = subtopic.name === "Primary Cause of Russia's 2022 Invasion of Ukraine";

  return (
    <div className="subtopic-container wider-container">
      <Helmet>
        <title>{subtopic.name} - The Other Side</title>
        <meta name="description" content={`Explore balanced perspectives and comprehensive information on ${subtopic.name}.`} />
        <meta name="keywords" content={`balanced perspectives, ${subtopic.name}, ${parentTopic ? parentTopic.name : ''}`} />
        <link rel="canonical" href={`https://www.theotherside.media/subtopics/${generateUrl(subtopic.name)}`} />
      </Helmet>
      <div className="subtopic-header">
        <h2>{subtopic.name}</h2>
        <p className="centered-description">{subtopic.description}</p>
        {parentTopic && (
          <Link to={`/topics/${generateUrl(parentTopic.name)}`} className="back-link">Back to {parentTopic.name}</Link>
        )}
      </div>
      {isToggleSubtopic && (
        <div className="viewpoint-toggle">
          <label className="toggle-label">{showOptionOne ? 'Putin\'s desire to return Russia to Power' : 'NATO expansion and western policies'}</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={showOptionOne}
              onChange={() => setShowOptionOne(!showOptionOne)}
            />
            <span className="slider"></span>
          </label>
        </div>
      )}
      <div className="media-section">
        <h3>Videos</h3>
        <div className="videos-grid">
          {isToggleSubtopic ? (
            showOptionOne ? (
              Array.isArray(subtopic.videos) && subtopic.videos.map((video, index) => (
                <div key={index} className="media-item">
                  <h4>{video.title}</h4>
                  <p>{video.summary}</p>
                  <div className="media-wrapper">
                    {video.thumbnail && !videoPlayer[index] ? (
                      <div className="thumbnail-wrapper" onClick={() => handleThumbnailClick(index, video.link)}>
                        <img src={video.thumbnail} alt={video.title} />
                        <div className="play-button">&#9658;</div>
                      </div>
                    ) : (
                      <iframe
                        src={videoPlayer[index] || `https://www.youtube.com/embed/${new URL(video.link).searchParams.get('v')}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={video.title}
                      ></iframe>
                    )}
                  </div>
                </div>
              ))
            ) : (
              Array.isArray(subtopic.opposingVideos) && subtopic.opposingVideos.map((video, index) => (
                <div key={index} className="media-item">
                  <h4>{video.title}</h4>
                  <p>{video.summary}</p>
                  <div className="media-wrapper">
                    {video.thumbnail && !videoPlayer[index] ? (
                      <div className="thumbnail-wrapper" onClick={() => handleThumbnailClick(index, video.link)}>
                        <img src={video.thumbnail} alt={video.title} />
                        <div className="play-button">&#9658;</div>
                      </div>
                    ) : (
                      <iframe
                        src={videoPlayer[index] || `https://www.youtube.com/embed/${new URL(video.link).searchParams.get('v')}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={video.title}
                      ></iframe>
                    )}
                  </div>
                </div>
              ))
            )
          ) : (
            Array.isArray(subtopic.videos) && subtopic.videos.map((video, index) => (
              <div key={index} className="media-item">
                <h4>{video.title}</h4>
                <p>{video.summary}</p>
                <div className="media-wrapper">
                  {video.thumbnail && !videoPlayer[index] ? (
                    <div className="thumbnail-wrapper" onClick={() => handleThumbnailClick(index, video.link)}>
                      <img src={video.thumbnail} alt={video.title} />
                      <div className="play-button">&#9658;</div>
                    </div>
                  ) : (
                    <iframe
                      src={videoPlayer[index] || `https://www.youtube.com/embed/${new URL(video.link).searchParams.get('v')}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={video.title}
                    ></iframe>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
        <br></br>
        <h3>Audio</h3>
        <div className="media-grid">
          {Array.isArray(subtopic.audio) && subtopic.audio.map((audio, index) => (
            <div key={index} className="media-item">
              <h4>{audio.title}</h4>
              <p>{audio.summary}</p>
              {audio.embed ? (
                <div dangerouslySetInnerHTML={{ __html: audio.embed }} />
              ) : (
                <div className="media-wrapper">
                  <audio controls>
                    <source src={audio.link} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="comments-section">
        <h3>Discussion Section</h3>
        <ul className="comments-list">
          {Array.isArray(comments) && comments.map((comment, index) => (
            <li key={index} className="comment-item">
              <div className="comment-content">
                <div className="comment-header">
                  <strong>{comment.username}</strong>
                </div>
                <p>{comment.content}</p>
                {Array.isArray(comment.replies) && comment.replies.map((reply, replyIndex) => (
                  <div key={replyIndex} className="reply-item">
                    <div className="comment-header">
                      <strong>{reply.username}</strong>
                    </div>
                    <p>{reply.content}</p>
                  </div>
                ))}
                {user && (
                  <>
                    <span className="reply-button" onClick={() => setReplyCommentId(comment._id)}>Reply </span>
                    {user.username === comment.username && (
                      <span className="reply-button delete-button" onClick={() => handleDeleteComment(comment._id)}>Delete</span>
                    )}
                  </>
                )}
                {replyCommentId === comment._id && (
                  <form onSubmit={(e) => handleReplySubmit(e, comment._id)} className="reply-form">
                    <textarea
                      value={replyContent}
                      onChange={(e) => setReplyContent(e.target.value)}
                      placeholder="Write a reply..."
                      rows="1"
                      required
                    />
                    <button type="submit">Submit</button>
                  </form>
                )}
              </div>
            </li>
          ))}
        </ul>
        {user && (
          <form onSubmit={handleCommentSubmit} className="comment-form">
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Express your thoughts..."
              rows="1"
              maxLength={MAX_COMMENT_LENGTH}
              required
            />
            <button type="submit">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SubtopicDetail;