import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} The Other Side. All rights reserved.</p>
      <p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdklLXQ6Xn8bZ6bBQrQlzHYILyjPEJeEfIZsduw2buXOOTwcw/viewform?usp=header" target="_blank" rel="noopener noreferrer">Submit Feedback</a> | <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a>
      </p>
    </footer>
  );
};

export default Footer;
