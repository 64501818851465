import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import './Register.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, { email, password, username });
      setMessage('Registration successful!');
    } catch (error) {
      setMessage('Registration failed. Please try again.');
    }
  };

  return (
    <div className="container mt-5 register-container">
      <Helmet>
        <title>Register - The Other Side</title>
        <meta name="description" content="Register on The Other Side to unlock premium content, personalize your feed, save your favorite articles and videos, and engage with the community." />
        <meta name="keywords" content="register, premium content, personalized feed, save articles, community engagement" />
        <link rel="canonical" href="https://www.theotherside.media/register" />
      </Helmet>
      <div className="row">
        <div className="col-md-6">
          <h2>Sign Up Here</h2>
          {message && <div className="alert alert-info">{message}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="registerEmail">Email address</label>
              <input
                type="email"
                className="form-control"
                id="registerEmail"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="registerUsername">Username</label>
              <input
                type="text"
                className="form-control"
                id="registerUsername"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="registerPassword">Password</label>
              <input
                type="password"
                className="form-control"
                id="registerPassword"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary">Register</button>
          </form>
        </div>
        <div className="col-md-6 register-benefits">
          <h4>Why Register?</h4>
          <ul>
            <li>Be the first to hear about new topics and features.</li>
            <li>Join the discussion by leaving comments on subtopic pages.</li>
            <li>Stay informed with updates delivered straight to your inbox.</li>
            <li>Help shape the future of the platform with your feedback.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Register;
